<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-card title="AxisRoom Details">
          <validation-observer ref="axisRoomIDVerification">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="AxisRoom ID"
                      rules="required"
                    >
                      <b-form-input
                        v-model="axisRoomID"
                        :state="errors.length > 0 ? false : null"
                        placeholder="AxisRoom ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="updateAxisID"
                  >
                    <b-spinner v-if="isUpdatingAxisRoomId" small></b-spinner>
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card title="Actions">
          <router-link :to="`/hotel/${hotelID}/inventory-data`"
            >Update Inventory Data</router-link
          >
        </b-card>
      </b-col>
    </b-row>

    <b-tabs>
      <b-tab active title="English">
        <b-card title="Basic Details">
          <validation-observer ref="basicDetailsFormValidation">
            <b-form>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Hotel Name">
                    <validation-provider
                      #default="{ errors }"
                      name="Hotel Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="hotel.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Hotel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="City">
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <b-form-input
                        v-model="hotel.city"
                        :state="errors.length > 0 ? false : null"
                        placeholder="City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Location">
                    <validation-provider
                      #default="{ errors }"
                      name="Location"
                      rules="required"
                    >
                      <b-form-input
                        v-model="hotel.location.locationName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lat)">
                    <validation-provider
                      #default="{ errors }"
                      name="Latitude"
                      rules="required"
                    >
                      <b-form-input
                        v-model="hotel.location.coordinates[0]"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Coordinates (lat)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Coordinates (lon)">
                    <validation-provider
                      #default="{ errors }"
                      name="Longitude"
                      rules="required"
                    >
                      <b-form-input
                        v-model="hotel.location.coordinates[1]"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Coordinates (lon)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Hotel Themes">
                    <v-select
                      multiple
                      v-model="hotel.themes"
                      :reduce="
                        (theme) => {
                          return { theme: theme._id };
                        }
                      "
                      label="name"
                      placeholder="Select Hotel Theme(s)"
                      :options="themes"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Property Type">
                    <v-select
                      v-model="hotel.propertyType"
                      :reduce="
                        (propertyType) => {
                          return propertyType._id;
                        }
                      "
                      label="name"
                      placeholder="Property Type"
                      :options="propertyTypes"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Property Size">
                    <v-select
                      v-model="hotel.hotelSize"
                      label="name"
                      placeholder="Property Size"
                      :options="propertySizes"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Property Manager">
                    <v-select
                      v-model="hotel.resortManager"
                      :reduce="
                        (name) => {
                          return name._id;
                        }
                      "
                      label="name"
                      placeholder="Resort Manager"
                      :options="propertyManagers"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="MTDC Pointer Thumbnail Url">
                    <validation-provider
                      #default="{ errors }"
                      name=""
                      rules=""
                    >
                      <b-form-input
                        v-model="hotel.pointerThumbNail"
                        :state="errors.length > 0 ? false : null"
                        placeholder="MTDC Pointer Thumbnail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>

        <b-card title="Hotel Gallery">
          <div class="mb-1 hotel-gallery" v-if="hotel.gallery.length > 0">
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in hotel.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHotelGalleryMedia(null, index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
            :params="{ lang: null }"
            :selectedMediaFiles="hotel.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>

        <b-card title="Hotel Videos">
          <b-form
            ref="foodToEatform"
            class="repeater-form"
            @submit.prevent="repeatHotelVideo(null)"
          >
            <b-row
              v-for="(video, index) in hotel.hotelVideos"
              :id="index"
              :key="`youtube-video-${index}`"
              ref="hotelYTvideoRow"
            >
              <b-col md="9">
                <b-form-group label="YT Video Link" label-for="yt-link">
                  <b-form-input
                    v-model="hotel.hotelVideos[index].src"
                    type="text"
                    placeholder="Enter YouTube Video ID"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeHotelVideo(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatHotelVideo(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.hotelVideos.length === 0 ? "Add Video" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="About the Property">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title" label-for="sectionTitle">
                <b-form-input
                  v-model="hotel.sectionTitle.description"
                  id="sectionTitle"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Attractions" label-for="hotelAttractions">
                <v-select
                  multiple
                  label="title"
                  v-model="hotel.attractions"
                  :reduce="
                    (attraction) => {
                      return { attraction: attraction._id };
                    }
                  "
                  placeholder="Select Hotel Attraction(s)"
                  :options="attractions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <quill-editor v-model="hotel.description" :options="snowOption" />
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Things To Do">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title" label-for="sectionTitleToDo">
                <b-form-input
                  v-model="hotel.sectionTitle.thingsToDo"
                  id="sectionTitleToDo"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Things To Do" label-for="thingstodo">
                <v-select
                  multiple
                  label="title"
                  v-model="hotel.thingsToDos"
                  :reduce="
                    (thingsToDo) => {
                      return {
                        thingsToDo: thingsToDo._id,
                        title: thingsToDo.title,
                      };
                    }
                  "
                  placeholder="Select Things To Do"
                  :options="toDos"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="hotel.thingsToDos.length" cols="12">
              <span>Things To Do - Order</span>
              <draggable
                v-model="hotel.thingsToDos"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(toDo, index) in hotel.thingsToDos"
                  :key="index"
                  tag="li"
                >
                  <div class="d-flex align-items-center">
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ toDo.title }}
                      </b-card-text>
                    </div>
                    <div class="ml-3">
                      <b-form-group class="mb-0">
                        <b-form-input
                          v-model="hotel.thingsToDos[index].distance"
                          placeholder="Distance from the hotel"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Amenities">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleAmenities"
              >
                <b-form-input
                  v-model="hotel.sectionTitle.amenities"
                  id="sectionTitleAmenities"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form
            ref="amenitySetform"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-row
              v-for="(amenity, index) in hotel.amenitySet"
              :id="amenity.id"
              :key="amenity.id"
              ref="amenityrow"
            >
              <b-col md="3">
                <b-form-group label="Category" label-for="category-name">
                  <b-form-input
                    v-model="hotel.amenitySet[index].categoryName"
                    type="text"
                    placeholder="Category Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group label="Amenities" label-for="amenities">
                  <v-select
                    multiple
                    label="name"
                    v-model="hotel.amenitySet[index].amenities"
                    :reduce="
                      (amenity) => {
                        return { amenity: amenity._id };
                      }
                    "
                    placeholder="Select Amenities"
                    :options="amenities"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAmenityItem(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAmenityItemAgain(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.amenitySet.length === 0
                      ? "Add Amenities"
                      : "Add New Category"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Food to Eat">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleFoodToEat"
              >
                <b-form-input
                  v-model="hotel.sectionTitle.foodToEat"
                  id="sectionTitleFoodToEat"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form
            ref="foodToEatform"
            class="repeater-form"
            @submit.prevent="repeatFoodToEat(null)"
          >
            <b-row
              v-for="(food, index) in hotel.foodToEat"
              :id="index"
              :key="food.id"
              ref="foodToEatrow"
            >
              <b-col md="7">
                <b-form-group label="Food" label-for="food-name">
                  <b-form-input
                    v-model="hotel.foodToEat[index].title"
                    type="text"
                    placeholder="Food"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="hotel.foodToEat[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="mainProps"
                  v-if="hotel.foodToEat[index].thumbnailImg"
                  :src="hotel.foodToEat[index].thumbnailImg"
                ></b-img>
                <ComponentMediaSelector
                  :params="{ index, lang: null }"
                  @mediaSelected="handleFoodMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeFoodToEatItem(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatFoodToEat(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.foodToEat.length === 0 ? "Add Foods" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Hotel Policy">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleHotelPolicy"
              >
                <b-form-input
                  v-model="hotel.sectionTitle.policy"
                  id="sectionTitleHotelPolicy"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form
                ref="policyform"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <b-row
                  v-for="(policy, index) in hotel.policy"
                  :id="index"
                  :key="index"
                  ref="policyrow"
                >
                  <b-col md="7">
                    <b-form-group label="Description" label-for="Description">
                      <b-form-textarea
                        v-model="hotel.policy[index]"
                        type="text"
                        placeholder="Description"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" md="3" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removePolicyItem(null, index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatPolicy(null)"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>{{
                        hotel.policy.length === 0 ? "Add Policy" : "Add New"
                      }}</span>
                    </b-button></b-col
                  >
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Cancellation Policy">
          <quill-editor
            v-model="hotel.cancellationPolicy"
            :options="snowOption"
          />
        </b-card>

        <b-row>
          <b-col>
            <b-card title="Hotel Contact Details">
              <validation-observer ref="contactDetailsValidation">
                <b-form>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Section Heading">
                        <validation-provider
                          #default="{ errors }"
                          name="Section Heading"
                          rules="required"
                        >
                          <b-form-input
                            v-model="hotel.sectionTitle.contact"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Section Heading"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Mobile Number">
                        <b-form-input
                          v-model="hotel.phone"
                          placeholder="Mobile Number"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Email Address">
                        <validation-provider
                          #default="{ errors }"
                          name="Email Address"
                          rules="required"
                        >
                          <b-form-input
                            v-model="hotel.email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Hotel Address">
                        <b-form-textarea
                          v-model="hotel.address"
                          placeholder="Address"
                          rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Direction URL (Google Maps Link)">
                        <b-form-input
                          v-model="hotel.directionUrl"
                          placeholder="Maps URL"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="Hotel Location Details">
              <b-form-group label="Section Heading">
                <b-form-input
                  v-model="hotel.sectionTitle.locationDetails"
                  placeholder="Section Heading"
                />
              </b-form-group>
              <span class="mb-1 d-block"
                >Details about the Location (Nearest Railway Station And
                Airport)</span
              >
              <b-form
                ref="locationDetailsform"
                class="repeater-form"
                @submit.prevent="repeatLocationDetail(null)"
              >
                <b-row
                  v-for="(detail, index) in hotel.locationDetails"
                  :id="`locationdetail` + index"
                  :key="`locationdetail` + index"
                  ref="locationDetailrow"
                >
                  <b-col md="8">
                    <b-form-textarea
                      v-model="hotel.locationDetails[index]"
                      placeholder="Location Detail"
                      rows="2"
                    />
                  </b-col>
                  <b-col md="4" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removeLocationDetailItem(null, index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatLocationDetail(null)"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>{{
                        hotel.locationDetails.length === 0
                          ? "Add Location Detail"
                          : "Add New"
                      }}</span>
                    </b-button></b-col
                  >
                </b-row>
              </b-form>
            </b-card>
            <b-card title="Check-In and Check-Out Time">
              <div>
                <b-form-group label="Check-In">
                  <b-form-timepicker
                    v-model="hotel.checkIn"
                    locale="en"
                  ></b-form-timepicker>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="Check-Out">
                  <b-form-timepicker
                    v-model="hotel.checkOut"
                    locale="en"
                  ></b-form-timepicker>
                </b-form-group>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <!-- hindi translation -->

      <b-tab title="Hindi">
        <b-card title="Basic Details">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group label="Hotel Name">
                  <b-form-input
                    v-model="hotel.translations.hi.name"
                    placeholder="Hotel Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="City">
                  <b-form-input
                    v-model="hotel.translations.hi.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Location">
                  <b-form-input
                    v-model="hotel.translations.hi.location.locationName"
                    placeholder="Location"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Coordinates (lat)">
                  <b-form-input
                    v-model="hotel.translations.hi.location.coordinates[0]"
                    placeholder="Coordinates (lat)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Coordinates (lon)">
                  <b-form-input
                    v-model="hotel.translations.hi.location.coordinates[1]"
                    placeholder="Coordinates (lon)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Hotel Themes">
                  <v-select
                    multiple
                    v-model="hotel.translations.hi.themes"
                    :reduce="
                      (theme) => {
                        return { theme: theme._id };
                      }
                    "
                    label="name"
                    placeholder="Select Hotel Theme(s)"
                    :options="themes"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Property Type">
                  <v-select
                    v-model="hotel.translations.hi.propertyType"
                    :reduce="
                      (propertyType) => {
                        return propertyType._id;
                      }
                    "
                    label="name"
                    placeholder="Property Type"
                    :options="propertyTypes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Hotel Gallery">
          <div
            class="mb-1 hotel-gallery"
            v-if="hotel.translations.hi.gallery.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in hotel.translations.hi.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHotelGalleryMedia('hi', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
            :params="{ lang: 'hi' }"
            :selectedMediaFiles="hotel.translations.hi.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>

        <b-card title="About the Property">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title" label-for="sectionTitle">
                <b-form-input
                  v-model="hotel.translations.hi.sectionTitle.description"
                  id="sectionTitle"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Attractions" label-for="hotelAttractions">
                <v-select
                  multiple
                  label="title"
                  v-model="hotel.translations.hi.attractions"
                  :reduce="
                    (attraction) => {
                      return { attraction: attraction._id };
                    }
                  "
                  placeholder="Select Hotel Attraction(s)"
                  :options="attractions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <quill-editor
                v-model="hotel.translations.hi.description"
                :options="snowOption"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Things To Do">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title" label-for="sectionTitleToDo">
                <b-form-input
                  v-model="hotel.translations.hi.sectionTitle.thingsToDo"
                  id="sectionTitleToDo"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Things To Do" label-for="thingstodo">
                <v-select
                  multiple
                  label="title"
                  v-model="hotel.translations.hi.thingsToDos"
                  :reduce="
                    (thingsToDo) => {
                      return {
                        thingsToDo: thingsToDo._id,
                        title: thingsToDo.title,
                      };
                    }
                  "
                  placeholder="Select Things To Do"
                  :options="toDos"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="hotel.translations.hi.thingsToDos.length" cols="12">
              <span>Things To Do - Order</span>
              <draggable
                v-model="hotel.translations.hi.thingsToDos"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(toDo, index) in hotel.translations.hi.thingsToDos"
                  :key="index"
                  tag="li"
                >
                  <div class="d-flex align-items-center">
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ toDo.title }}
                      </b-card-text>
                    </div>
                    <div class="ml-3">
                      <b-form-group class="mb-0">
                        <b-form-input
                          v-model="
                            hotel.translations.hi.thingsToDos[index].distance
                          "
                          placeholder="Distance from the hotel"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Amenities">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleAmenities"
              >
                <b-form-input
                  v-model="hotel.translations.hi.sectionTitle.amenities"
                  id="sectionTitleAmenities"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form
            ref="amenitySetform"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-row
              v-for="(amenity, index) in hotel.translations.hi.amenitySet"
              :id="amenity.id"
              :key="amenity.id"
              ref="amenityrow"
            >
              <b-col md="3">
                <b-form-group label="Category" label-for="category-name">
                  <b-form-input
                    v-model="
                      hotel.translations.hi.amenitySet[index].categoryName
                    "
                    type="text"
                    placeholder="Category Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group label="Amenities" label-for="amenities">
                  <v-select
                    multiple
                    label="name"
                    v-model="hotel.translations.hi.amenitySet[index].amenities"
                    :reduce="
                      (amenity) => {
                        return { amenity: amenity._id };
                      }
                    "
                    placeholder="Select Amenities"
                    :options="amenities"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAmenityItem('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAmenityItemAgain('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.translations.hi.amenitySet.length === 0
                      ? "Add Amenities"
                      : "Add New Category"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Food to Eat">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleFoodToEat"
              >
                <b-form-input
                  v-model="hotel.translations.hi.sectionTitle.foodToEat"
                  id="sectionTitleFoodToEat"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form
            ref="foodToEatform"
            class="repeater-form"
            @submit.prevent="repeatFoodToEat('hi')"
          >
            <b-row
              v-for="(food, index) in hotel.translations.hi.foodToEat"
              :id="index"
              :key="food.id"
              ref="foodToEatrow"
            >
              <b-col md="7">
                <b-form-group label="Food" label-for="food-name">
                  <b-form-input
                    v-model="hotel.translations.hi.foodToEat[index].title"
                    type="text"
                    placeholder="Food"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="hotel.translations.hi.foodToEat[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="mainProps"
                  v-if="hotel.translations.hi.foodToEat[index].thumbnailImg"
                  :src="hotel.translations.hi.foodToEat[index].thumbnailImg"
                ></b-img>
                <ComponentMediaSelector
                  :params="{ index, lang: 'hi' }"
                  @mediaSelected="handleFoodMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeFoodToEatItem('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatFoodToEat('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.translations.hi.foodToEat.length === 0
                      ? "Add Foods"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Hotel Policy">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleHotelPolicy"
              >
                <b-form-input
                  v-model="hotel.translations.hi.sectionTitle.policy"
                  id="sectionTitleHotelPolicy"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form
                ref="policyform"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <b-row
                  v-for="(policy, index) in hotel.translations.hi.policy"
                  :id="index"
                  :key="index"
                  ref="policyrow"
                >
                  <b-col md="7">
                    <b-form-group label="Description" label-for="Description">
                      <b-form-textarea
                        v-model="hotel.translations.hi.policy[index]"
                        type="text"
                        placeholder="Description"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" md="3" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removePolicyItem('hi', index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatPolicy('hi')"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>{{
                        hotel.translations.hi.policy.length === 0
                          ? "Add Policy"
                          : "Add New"
                      }}</span>
                    </b-button></b-col
                  >
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Cancellation Policy">
          <quill-editor
            v-model="hotel.translations.hi.cancellationPolicy"
            :options="snowOption"
          />
        </b-card>

        <b-row>
          <b-col>
            <b-card title="Hotel Contact Details">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Section Heading">
                      <b-form-input
                        v-model="hotel.translations.hi.sectionTitle.contact"
                        placeholder="Section Heading"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Mobile Number">
                      <b-form-input
                        v-model="hotel.translations.hi.phone"
                        placeholder="Mobile Number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Email Address">
                      <b-form-input
                        v-model="hotel.translations.hi.email"
                        placeholder="Email Address"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Hotel Address">
                      <b-form-textarea
                        v-model="hotel.translations.hi.address"
                        placeholder="Address"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Direction URL (Google Maps Link)">
                      <b-form-input
                        v-model="hotel.translations.hi.directionUrl"
                        placeholder="Maps URL"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="Hotel Location Details">
              <b-form-group label="Section Heading">
                <b-form-input
                  v-model="hotel.translations.hi.sectionTitle.locationDetails"
                  placeholder="Section Heading"
                />
              </b-form-group>
              <span class="mb-1 d-block"
                >Details about the Location (Nearest Railway Station And
                Airport)</span
              >
              <b-form
                ref="locationDetailsform"
                class="repeater-form"
                @submit.prevent="repeatLocationDetail('hi')"
              >
                <b-row
                  v-for="(detail, index) in hotel.translations.hi
                    .locationDetails"
                  :id="`locationdetail` + index"
                  :key="`locationdetail` + index"
                  ref="locationDetailrow"
                >
                  <b-col md="8">
                    <b-form-textarea
                      v-model="hotel.translations.hi.locationDetails[index]"
                      placeholder="Location Detail"
                      rows="2"
                    />
                  </b-col>
                  <b-col md="4" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removeLocationDetailItem('hi', index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatLocationDetail('hi')"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>{{
                        hotel.translations.hi.locationDetails.length === 0
                          ? "Add Location Detail"
                          : "Add New"
                      }}</span>
                    </b-button></b-col
                  >
                </b-row>
              </b-form>
            </b-card>
            <b-card title="Check-In and Check-Out Time">
              <div>
                <b-form-group label="Check-In">
                  <b-form-timepicker
                    v-model="hotel.translations.hi.checkIn"
                    locale="en"
                  ></b-form-timepicker>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="Check-Out">
                  <b-form-timepicker
                    v-model="hotel.translations.hi.checkOut"
                    locale="en"
                  ></b-form-timepicker>
                </b-form-group>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>

      <!-- marathi translation -->

      <b-tab title="Marathi">
        <b-card title="Basic Details">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group label="Hotel Name">
                  <b-form-input
                    v-model="hotel.translations.mr.name"
                    placeholder="Hotel Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="City">
                  <b-form-input
                    v-model="hotel.translations.mr.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Location">
                  <b-form-input
                    v-model="hotel.translations.mr.location.locationName"
                    placeholder="Location"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Coordinates (lat)">
                  <b-form-input
                    v-model="hotel.translations.mr.location.coordinates[0]"
                    placeholder="Coordinates (lat)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Coordinates (lon)">
                  <b-form-input
                    v-model="hotel.translations.mr.location.coordinates[1]"
                    placeholder="Coordinates (lon)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Hotel Themes">
                  <v-select
                    multiple
                    v-model="hotel.translations.mr.themes"
                    :reduce="
                      (theme) => {
                        return { theme: theme._id };
                      }
                    "
                    label="name"
                    placeholder="Select Hotel Theme(s)"
                    :options="themes"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Property Type">
                  <v-select
                    v-model="hotel.translations.mr.propertyType"
                    :reduce="
                      (propertyType) => {
                        return propertyType._id;
                      }
                    "
                    label="name"
                    placeholder="Property Type"
                    :options="propertyTypes"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Hotel Gallery">
          <div
            class="mb-1 hotel-gallery"
            v-if="hotel.translations.mr.gallery.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in hotel.translations.mr.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeHotelGalleryMedia('mr', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
            :params="{ lang: 'mr' }"
            :selectedMediaFiles="hotel.translations.mr.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>

        <b-card title="About the Property">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title" label-for="sectionTitle">
                <b-form-input
                  v-model="hotel.translations.mr.sectionTitle.description"
                  id="sectionTitle"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Attractions" label-for="hotelAttractions">
                <v-select
                  multiple
                  label="title"
                  v-model="hotel.translations.mr.attractions"
                  :reduce="
                    (attraction) => {
                      return { attraction: attraction._id };
                    }
                  "
                  placeholder="Select Hotel Attraction(s)"
                  :options="attractions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <quill-editor
                v-model="hotel.translations.mr.description"
                :options="snowOption"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Things To Do">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title" label-for="sectionTitleToDo">
                <b-form-input
                  v-model="hotel.translations.mr.sectionTitle.thingsToDo"
                  id="sectionTitleToDo"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Things To Do" label-for="thingstodo">
                <v-select
                  multiple
                  label="title"
                  v-model="hotel.translations.mr.thingsToDos"
                  :reduce="
                    (thingsToDo) => {
                      return {
                        thingsToDo: thingsToDo._id,
                        title: thingsToDo.title,
                      };
                    }
                  "
                  placeholder="Select Things To Do"
                  :options="toDos"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="hotel.translations.mr.thingsToDos.length" cols="12">
              <span>Things To Do - Order</span>
              <draggable
                v-model="hotel.translations.mr.thingsToDos"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(toDo, index) in hotel.translations.mr.thingsToDos"
                  :key="index"
                  tag="li"
                >
                  <div class="d-flex align-items-center">
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ toDo.title }}
                      </b-card-text>
                    </div>
                    <div class="ml-3">
                      <b-form-group class="mb-0">
                        <b-form-input
                          v-model="
                            hotel.translations.mr.thingsToDos[index].distance
                          "
                          placeholder="Distance from the hotel"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Amenities">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleAmenities"
              >
                <b-form-input
                  v-model="hotel.translations.mr.sectionTitle.amenities"
                  id="sectionTitleAmenities"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form
            ref="amenitySetform"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-row
              v-for="(amenity, index) in hotel.translations.mr.amenitySet"
              :id="amenity.id"
              :key="amenity.id"
              ref="amenityrow"
            >
              <b-col md="3">
                <b-form-group label="Category" label-for="category-name">
                  <b-form-input
                    v-model="
                      hotel.translations.mr.amenitySet[index].categoryName
                    "
                    type="text"
                    placeholder="Category Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group label="Amenities" label-for="amenities">
                  <v-select
                    multiple
                    label="name"
                    v-model="hotel.translations.mr.amenitySet[index].amenities"
                    :reduce="
                      (amenity) => {
                        return { amenity: amenity._id };
                      }
                    "
                    placeholder="Select Amenities"
                    :options="amenities"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAmenityItem('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAmenityItemAgain('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.translations.mr.amenitySet.length === 0
                      ? "Add Amenities"
                      : "Add New Category"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Food to Eat">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleFoodToEat"
              >
                <b-form-input
                  v-model="hotel.translations.mr.sectionTitle.foodToEat"
                  id="sectionTitleFoodToEat"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form
            ref="foodToEatform"
            class="repeater-form"
            @submit.prevent="repeatFoodToEat('mr')"
          >
            <b-row
              v-for="(food, index) in hotel.translations.mr.foodToEat"
              :id="index"
              :key="food.id"
              ref="foodToEatrow"
            >
              <b-col md="7">
                <b-form-group label="Food" label-for="food-name">
                  <b-form-input
                    v-model="hotel.translations.mr.foodToEat[index].title"
                    type="text"
                    placeholder="Food"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="hotel.translations.mr.foodToEat[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="mainProps"
                  v-if="hotel.translations.mr.foodToEat[index].thumbnailImg"
                  :src="hotel.translations.mr.foodToEat[index].thumbnailImg"
                ></b-img>
                <ComponentMediaSelector
                  :params="{ index, lang: 'mr' }"
                  @mediaSelected="handleFoodMediaSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeFoodToEatItem('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatFoodToEat('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    hotel.translations.mr.foodToEat.length === 0
                      ? "Add Foods"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Hotel Policy">
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Section Title"
                label-for="sectionTitleHotelPolicy"
              >
                <b-form-input
                  v-model="hotel.translations.mr.sectionTitle.policy"
                  id="sectionTitleHotelPolicy"
                  placeholder="Section Title"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form
                ref="policyform"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <b-row
                  v-for="(policy, index) in hotel.translations.mr.policy"
                  :id="index"
                  :key="index"
                  ref="policyrow"
                >
                  <b-col md="7">
                    <b-form-group label="Description" label-for="Description">
                      <b-form-textarea
                        v-model="hotel.translations.mr.policy[index]"
                        type="text"
                        placeholder="Description"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="2" md="3" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removePolicyItem('mr', index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatPolicy('mr')"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>{{
                        hotel.translations.mr.policy.length === 0
                          ? "Add Policy"
                          : "Add New"
                      }}</span>
                    </b-button></b-col
                  >
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Cancellation Policy">
          <quill-editor
            v-model="hotel.translations.mr.cancellationPolicy"
            :options="snowOption"
          />
        </b-card>

        <b-row>
          <b-col>
            <b-card title="Hotel Contact Details">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Section Heading">
                      <b-form-input
                        v-model="hotel.translations.mr.sectionTitle.contact"
                        placeholder="Section Heading"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Mobile Number">
                      <b-form-input
                        v-model="hotel.translations.mr.phone"
                        placeholder="Mobile Number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Email Address">
                      <b-form-input
                        v-model="hotel.translations.mr.email"
                        placeholder="Email Address"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Hotel Address">
                      <b-form-textarea
                        v-model="hotel.translations.mr.address"
                        placeholder="Address"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Direction URL (Google Maps Link)">
                      <b-form-input
                        v-model="hotel.translations.mr.directionUrl"
                        placeholder="Maps URL"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="Hotel Location Details">
              <b-form-group label="Section Heading">
                <b-form-input
                  v-model="hotel.translations.mr.sectionTitle.locationDetails"
                  placeholder="Section Heading"
                />
              </b-form-group>
              <span class="mb-1 d-block"
                >Details about the Location (Nearest Railway Station And
                Airport)</span
              >
              <b-form
                ref="locationDetailsform"
                class="repeater-form"
                @submit.prevent="repeatLocationDetail('mr')"
              >
                <b-row
                  v-for="(detail, index) in hotel.translations.mr
                    .locationDetails"
                  :id="`locationdetail` + index"
                  :key="`locationdetail` + index"
                  ref="locationDetailrow"
                >
                  <b-col md="8">
                    <b-form-textarea
                      v-model="hotel.translations.mr.locationDetails[index]"
                      placeholder="Location Detail"
                      rows="2"
                    />
                  </b-col>
                  <b-col md="4" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removeLocationDetailItem('mr', index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    ><b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatLocationDetail('mr')"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>{{
                        hotel.translations.mr.locationDetails.length === 0
                          ? "Add Location Detail"
                          : "Add New"
                      }}</span>
                    </b-button></b-col
                  >
                </b-row>
              </b-form>
            </b-card>
            <b-card title="Check-In and Check-Out Time">
              <div>
                <b-form-group label="Check-In">
                  <b-form-timepicker
                    v-model="hotel.translations.mr.checkIn"
                    locale="en"
                  ></b-form-timepicker>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="Check-Out">
                  <b-form-timepicker
                    v-model="hotel.translations.mr.checkOut"
                    locale="en"
                  ></b-form-timepicker>
                </b-form-group>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <b-card class="text-right">
      <b-button @click="updateHotel" size="lg" block variant="primary"
        >Update Hotel</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTimepicker,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BImg,
  BFormTextarea,
  BSpinner,
  BListGroupItem,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import { required, email } from "@validations";
import vSelect from "vue-select";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    quillEditor,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormTimepicker,
    BFormInput,
    BFormGroup,
    BTabs,
    BTab,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    ComponentMediaSelector,
    BImg,
    BSpinner,
    BListGroupItem,
    draggable,
    Prism,
  },
  computed: {
    getNextAmenityID() {
      return this.hotel.amenitySet.length + 1;
    },
    getNextFoodToEatID() {
      return this.hotel.foodToEat.length + 1;
    },
    getNextPolicyID() {
      return this.hotel.policy.length + 1;
    },
    getNextlocationDetailsID() {
      return this.hotel.locationDetails.length + 1;
    },
  },
  data() {
    return {
      required,
      email,
      isDataLoading: true,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      mainProps: {
        width: 100,
        height: 100,
      },
      nextAmenityItemID: this.getNextAmenityID,
      nextFoodToEatID: this.getNextFoodToEatID,
      nextPolicyID: this.getNextPolicyID,
      nextlocationDetailsID: this.getNextlocationDetailsID,
      themes: [],
      attractions: [],
      amenities: [],
      toDos: [],
      propertyTypes: [],
      propertySizes: ["large", "small"],
      propertyManagers:[],
      hotelID: "",
      axisRoomID: "",
      isUpdatingAxisRoomId: false,
      hotel: {
        name: "",
        phone: "",
        email: "",
        city: "",
        location: {
          coordinates: ["", ""],
          locationName: "",
        },
        propertyType: "",
        address: "",
        directionUrl: "",
        description: "",
        attractions: [],
        gallery: [],
        hotelVideos: [],
        amenitySet: [],
        foodToEat: [],
        thingsToDos: [],
        themes: [],
        sectionTitle: {
          description: "",
          thingsToDo: "",
          amenities: "",
          foodToEat: "",
          policy: "",
          contact: "",
          locationDetails: "",
        },
        checkIn: "",
        checkOut: "",
        policy: [],
        locationDetails: [],
        cancellationPolicy: "",
        directionUrl: "",
        translations: {
          hi: {
            name: "",
            phone: "",
            email: "",
            city: "",
            location: {
              coordinates: ["", ""],
              locationName: "",
            },
            propertyType: "",
            address: "",
            directionUrl: "",
            description: "",
            attractions: [],
            gallery: [],
            amenitySet: [],
            foodToEat: [],
            thingsToDos: [],
            themes: [],
            sectionTitle: {
              description: "",
              thingsToDo: "",
              amenities: "",
              foodToEat: "",
              policy: "",
              contact: "",
              locationDetails: "",
            },
            checkIn: "",
            checkOut: "",
            policy: [],
            locationDetails: [],
            cancellationPolicy: "",
            directionUrl: "",
          },
          mr: {
            name: "",
            phone: "",
            email: "",
            city: "",
            location: {
              coordinates: ["", ""],
              locationName: "",
            },
            propertyType: "",
            address: "",
            directionUrl: "",
            description: "",
            attractions: [],
            gallery: [],
            amenitySet: [],
            foodToEat: [],
            thingsToDos: [],
            themes: [],
            sectionTitle: {
              description: "",
              thingsToDo: "",
              amenities: "",
              foodToEat: "",
              policy: "",
              contact: "",
              locationDetails: "",
            },
            checkIn: "",
            checkOut: "",
            policy: [],
            locationDetails: [],
            cancellationPolicy: "",
            directionUrl: "",
          },
        },
        pointerThumbNail: "",
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    async updateHotel() {
      const basicDetailsValidation =
        await this.$refs.basicDetailsFormValidation.validate();
      const contactDetailsValidation =
        await this.$refs.contactDetailsValidation.validate();

      if (!basicDetailsValidation || !contactDetailsValidation) {
        this.makeToast("danger", "Error", "Please fix the errors to continue.");
        return;
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/hotel/update`,
          { hotelID: this.hotelID, updates: this.hotel },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.makeToast(
            "success",
            "Success",
            `${response.data.name} has been updated successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while updating the hotel!");
        });
    },
    updateAxisID() {
      this.isUpdatingAxisRoomId = true;
      this.$refs.axisRoomIDVerification.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/updateAxisID`,
              {
                id: this.hotelID,
                hotelID: this.axisRoomID,
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.isUpdatingAxisRoomId = false;
              this.makeToast(
                "success",
                "Success",
                `AxisRoom ID has been updated successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.isUpdatingAxisRoomId = false;
              this.makeToast(
                "danger",
                "Error",
                "Error while updating the AxisRoom ID!"
              );
            });
        } else {
          this.isUpdatingAxisRoomId = false;
        }
      });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.hotel.translations[mediaObject.params.lang].gallery =
            mediaObject.media;
        } else {
          this.hotel.gallery = mediaObject.media;
        }
      }
    },
    handleHotelVideoSelect(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.hotel.translations[mediaObject.params.lang].hotelVideos =
            mediaObject.media;
        } else {
          this.hotel.hotelVideos = mediaObject.media;
        }
      }
    },
    handleFoodMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.hotel.translations[mediaObject.params.lang].foodToEat[
            mediaObject.params.index
          ].thumbnailImg = mediaObject.media.src;
        } else {
          this.hotel.foodToEat[mediaObject.params.index].thumbnailImg =
            mediaObject.media.src;
        }
      }
    },
    getHotel() {
      const hotelSlug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/cms/get/${hotelSlug}` , 
        {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        } )
        .then((response) => {
          const hotelID = response.data._id;
          this.hotel = response.data;
          this.hotelID = hotelID;

          if (response.data.axisHotelID) {
            this.axisRoomID = response.data.axisHotelID;
          }

          const attractions = response.data.attractions
            ? response.data.attractions.map((attraction) => {
                return {
                  attraction: attraction.attraction._id,
                };
              })
            : [];
          const attractionsHi = response.data.translations
            ? response.data.translations.hi.attractions
              ? response.data.translations.hi.attractions.map((attraction) => {
                  return {
                    attraction: attraction.attraction._id,
                  };
                })
              : []
            : [];
          this.hotel.translations.hi.attractions = attractionsHi;

          const attractionsMr = response.data.translations
            ? response.data.translations.mr.attractions
              ? response.data.translations.mr.attractions.map((attraction) => {
                  return {
                    attraction: attraction.attraction._id,
                  };
                })
              : []
            : [];
          this.hotel.translations.mr.attractions = attractionsMr;

          const themes = response.data.themes
            ? response.data.themes.map((theme) => {
                console.log("themes", theme.theme._id);
                return { theme: theme.theme._id };
              })
            : [];
          const themesHi = response.data.translations
            ? response.data.translations.hi.themes
              ? response.data.translations.hi.themes.map((theme) => {
                  return {
                    theme: theme.theme._id,
                  };
                })
              : []
            : [];

          this.hotel.translations.hi.themes = themesHi;

          const themesMr = response.data.translations
            ? response.data.translations.mr.themes
              ? response.data.translations.mr.themes.map((theme) => {
                  return {
                    theme: theme.theme._id,
                  };
                })
              : []
            : [];

          this.hotel.translations.mr.themes = themesMr;

          this.hotel.translations.hi.themes = themesHi;

          const thingsToDo = response.data.thingsToDos
            ? response.data.thingsToDos.map((toDo) => {
                return {
                  thingsToDo: toDo.thingsToDo._id,
                  title: toDo.thingsToDo.title,
                  distance: toDo.distance,
                };
              })
            : [];
          const thingsToDosHi = response.data.translations
            ? response.data.translations.hi.thingsToDos
              ? response.data.translations.hi.thingsToDos.map((toDo) => {
                  return {
                    thingsToDo: toDo.thingsToDo._id,
                    title: toDo.thingsToDo.title,
                    distance: toDo.distance,
                  };
                })
              : []
            : [];
          this.hotel.translations.hi.thingsToDos = thingsToDosHi;

          const thingsToDosMr = response.data.translations
            ? response.data.translations.mr.thingsToDos
              ? response.data.translations.mr.thingsToDos.map((toDo) => {
                  return {
                    thingsToDo: toDo.thingsToDo._id,
                    title: toDo.thingsToDo.title,
                    distance: toDo.distance,
                  };
                })
              : []
            : [];
          this.hotel.translations.mr.thingsToDos = thingsToDosMr;

          const amenities = response.data.amenitySet
            ? response.data.amenitySet.map((amenity, index) => {
                return {
                  id: index + 1,
                  categoryName: amenity.categoryName,
                  amenities: amenity.amenities.map((amenityObject) => {
                    return {
                      amenity: amenityObject.amenity._id,
                    };
                  }),
                };
              })
            : [];

          const amenitiesHi = response.data.translations
            ? response.data.translations.hi.amenitySet
              ? response.data.translations.hi.amenitySet.map(
                  (amenity, index) => {
                    return {
                      id: index + 1,
                      categoryName: amenity.categoryName,
                      amenities: amenity.amenities.map((amenityObject) => {
                        return {
                          amenity: amenityObject.amenity._id,
                        };
                      }),
                    };
                  }
                )
              : []
            : [];
          this.hotel.translations.hi.amenitySet = amenitiesHi;

          const amenitiesMr = response.data.translations
            ? response.data.translations.mr.amenitySet
              ? response.data.translations.mr.amenitySet.map(
                  (amenity, index) => {
                    return {
                      id: index + 1,
                      categoryName: amenity.categoryName,
                      amenities: amenity.amenities.map((amenityObject) => {
                        return {
                          amenity: amenityObject.amenity._id,
                        };
                      }),
                    };
                  }
                )
              : []
            : [];
          this.hotel.translations.mr.amenitySet = amenitiesMr;
          const hT = this.hotel.translations;
          hT.hi.location.coordinates = hT.hi.location.coordinates
            ? hT.hi.location.coordinates
            : [0, 0];
          hT.mr.location.coordinates = hT.mr.location.coordinates
            ? hT.mr.location.coordinates
            : [0, 0];
          hT.hi.sectionTitle = {
            description: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.description
                  ? hT.hi.sectionTitle.description
                  : ""
                : ""
              : "",
            thingsToDo: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.thingsToDo
                  ? hT.hi.sectionTitle.thingsToDo
                  : ""
                : ""
              : "",
            amenities: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.amenities
                  ? hT.hi.sectionTitle.amenities
                  : ""
                : ""
              : "",
            foodToEat: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.foodToEat
                  ? hT.hi.sectionTitle.foodToEat
                  : ""
                : ""
              : "",
            policy: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.policy
                  ? hT.hi.sectionTitle.policy
                  : ""
                : ""
              : "",
            contact: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.contact
                  ? hT.hi.sectionTitle.contact
                  : ""
                : ""
              : "",
            locationDetails: hT
              ? hT.hi.sectionTitle
                ? hT.hi.sectionTitle.locationDetails
                  ? hT.hi.sectionTitle.locationDetails
                  : ""
                : ""
              : "",
          };

          hT.mr.sectionTitle = {
            description: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.description
                  ? hT.mr.sectionTitle.description
                  : ""
                : ""
              : "",
            thingsToDo: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.thingsToDo
                  ? hT.mr.sectionTitle.thingsToDo
                  : ""
                : ""
              : "",
            amenities: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.amenities
                  ? hT.mr.sectionTitle.amenities
                  : ""
                : ""
              : "",
            foodToEat: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.foodToEat
                  ? hT.mr.sectionTitle.foodToEat
                  : ""
                : ""
              : "",
            policy: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.policy
                  ? hT.mr.sectionTitle.policy
                  : ""
                : ""
              : "",
            contact: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.contact
                  ? hT.mr.sectionTitle.contact
                  : ""
                : ""
              : "",
            locationDetails: hT
              ? hT.mr.sectionTitle
                ? hT.mr.sectionTitle.locationDetails
                  ? hT.mr.sectionTitle.locationDetails
                  : ""
                : ""
              : "",
          };

          this.hotel.themes = themes;
          this.hotel.attractions = attractions;
          this.hotel.thingsToDos = thingsToDo;
          this.hotel.amenitySet = amenities;
          this.hotel.pointerThumbNail = pointerThumbNail;
          this.isDataLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getAttractions() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/attractions/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.attractions = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Attractions Data");
        });
    },
    getHotelThemes() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/themes/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.themes = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Hotel Themes Data");
        });
    },
    getHotelAmenities() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/amenity/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.amenities = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Hotel Themes Data");
        });
    },
    getThingsToDo() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/thingsToDo/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.toDos = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Things To Do Data");
        });
    },
    getPropertyTypes() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/propertyType/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.propertyTypes = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Couldn't load Property Types Data"
          );
        });
    },
    getPropertyManagers() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/org/user/getUsersByRole?role=Resort-Manager`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.propertyManagers = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Couldn't load Property Types Data"
          );
        });
    },
    removeAmenityItem(lang = undefined, index) {
      if (lang) {
        this.hotel.translations[lang].amenitySet.splice(index, 1);
      } else {
        this.hotel.amenitySet.splice(index, 1);
      }
    },
    removeFoodToEatItem(lang = undefined, index) {
      if (lang) {
        this.hotel.translations[lang].foodToEat.splice(index, 1);
      } else {
        this.hotel.foodToEat.splice(index, 1);
      }
    },
    removePolicyItem(lang = undefined, index) {
      if (lang) {
        this.hotel.translations[lang].policy.splice(index, 1);
      } else {
        this.hotel.policy.splice(index, 1);
      }
    },
    removeLocationDetailItem(lang = undefined, index) {
      if (lang) {
        this.hotel.translations[lang].locationDetails.splice(index, 1);
      } else {
        this.hotel.locationDetails.splice(index, 1);
      }
    },
    repeateAmenityItemAgain(lang = undefined) {
      if (lang) {
        this.hotel.translations[lang].amenitySet.push({
          categoryName: "",
          amenities: [],
        });
      } else {
        this.hotel.amenitySet.push({
          categoryName: "",
          amenities: [],
        });
      }
    },
    repeatFoodToEat(lang = undefined) {
      if (lang) {
        this.hotel.translations[lang].foodToEat.push({
          title: "",
          description: "",
          thumbnailImg: "",
        });
      } else {
        this.hotel.foodToEat.push({
          title: "",
          description: "",
          thumbnailImg: "",
        });
      }
    },
    repeatHotelVideo(lang = undefined) {
      if (lang) {
        this.hotel.translations[lang].hotelVideos.push({
          src: "",
        });
      } else {
        this.hotel.hotelVideos.push({
          src: "",
        });
      }
    },
    removeHotelVideo(lang = undefined, index) {
      if (lang) {
        this.hotel.translations[lang].hotelVideos.splice(index, 1);
      } else {
        this.hotel.hotelVideos.splice(index, 1);
      }
    },
    repeatPolicy(lang = undefined) {
      if (lang) {
        this.hotel.translations[lang].policy.push("");
      } else {
        this.hotel.policy.push("");
      }
      this.nextPolicyID = this.nextPolicyID + 1;
    },
    repeatLocationDetail(lang = undefined) {
      if (lang) {
        this.hotel.translations[lang].locationDetails.push("");
      } else {
        this.hotel.locationDetails.push("");
      }
      this.nextlocationDetailsID = this.nextlocationDetailsID + 1;
    },
    removeHotelGalleryMedia(lang = undefined, index) {
      if (lang) {
        this.hotel.translations[lang].gallery.splice(index, 1);
      } else {
        this.hotel.gallery.splice(index, 1);
      }
    },
  },
  mounted() {
    this.getHotel();
    this.getHotelThemes();
    this.getAttractions();
    this.getThingsToDo();
    this.getHotelAmenities();
    this.getPropertyTypes();
    this.getPropertyManagers();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.hotel-gallery {
  display: flex;

  .gallery-item {
    position: relative;

    .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}
</style>